import "./EvaluatorPage1.css";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PendingEvalutionApi } from "../../apis/pendingevaluationapi/PendingEvalutionApi";
import Navbar from "../../components/navbar/Navbar";
import CustomNoRowsOverlay from "../../commomComponents/form/noformdata/NoFormData";

function EvaluatorPage1() {

    const email =
      JSON.parse(localStorage?.getItem("user"))?.username ||
      JSON.parse(localStorage?.getItem("india_user"))?.email;

  const { data = [], isLoading } = useQuery(
    ["application-data-evaluator", email],
    PendingEvalutionApi,
    {
      select: (data) => data?.data,
    }
  );
  const navigate = useNavigate();
  return (
    <div className="w-[100%] h-screen">
      <div>
        <Navbar track={6}></Navbar>
      </div>
      {data?.length === 0 ? (
        <div className="flex text-[20px] font-semibold mt-40 justify-center">
          <CustomNoRowsOverlay theory="You don't have any pending evaluations"></CustomNoRowsOverlay>
        </div>
      ) : (
        <div className=" flex flex-col items-center h-auto">
          <div className="flex -ml-[300px] text-[20px] font-semibold mt-10 ">
            Pending Evaluations
          </div>

          <div className="w-[600px] mt-10 ml-16">
            <table>
              <tr>
                <th>Applicant Name</th>
                <th>Country</th>
                <th>Application</th>
              </tr>
              <tbody>
                {data?.map((item) => (
                  <tr key={item?.id}>
                    <td>{item?.name}</td>
                    <td>{item?.country}</td>
                    <td>
                        <a
                          href={`/EvaluatorApplication/${item?.harmony_email}`}
                          className="text-[skyblue] underline cursor-pointer"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.preventDefault();
                            // localStorage.setItem(
                            //   "emailData",
                            //   JSON.stringify(item?.harmony_email)
                            // );
                            window.open(`/EvaluatorApplication/${item?.harmony_email}`, "_blank");
                          }}
                        >
                          View application & recommend applicant
                        </a>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default EvaluatorPage1;
